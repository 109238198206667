import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IOptionsApprovalResponseModel } from '../interfaces/options-approval-response.model';
import { environment } from '../../../../environments/environment';
import { IOptionsApprovalRequestModel } from '../interfaces/options-approval-request.model';

@Injectable({
    providedIn: 'root'
})
export class OptionsApprovalService {
    public readonly apiUrl: string = environment.apiUrl;
    public readonly baseUrl: string = '/api/OptionsApproval';

    public constructor(
        private readonly _http: HttpClient
    ) {
    }

    public async getOptionsApproval(timeValue: string, subDepartmentId: string, attributeValue: string,
                                    categoryId: string): Promise<IOptionsApprovalResponseModel> {
        return firstValueFrom(
            this._http.get<IOptionsApprovalResponseModel>
            (`${this.apiUrl + this.baseUrl}/${timeValue}/${subDepartmentId}/${attributeValue}?${categoryId}`)
        );
    }

    public async saveOptionsApproval(model: IOptionsApprovalRequestModel): Promise<IOptionsApprovalResponseModel> {
        return firstValueFrom(
            this._http.post<IOptionsApprovalResponseModel>(this.apiUrl + this.baseUrl, model)
        );
    }

    public async approveOptionsApproval(optionsApprovalId: number): Promise<IOptionsApprovalResponseModel> {
        return firstValueFrom(
            this._http.post<IOptionsApprovalResponseModel>(`${this.apiUrl + this.baseUrl}/approve/${optionsApprovalId}`, null)
        );
    }
    public getShortId(id: string): string {
        return id.split('-').pop() || '';
    }
}
