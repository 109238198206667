<h2 mat-dialog-title class="mb-0">Save board</h2>
<button mat-flat-button [mat-dialog-close]="false" mat-dialog-close>
    <img src="assets/icon-close.svg" width="14" height="auto" />
</button>

<div class="row mt-4 mb-5" *ngIf="boardId === 0">
    <div class="col-12">
        <p *ngIf="saveFirst && !isPatternBoard">Do you want to save filter changes before loading a saved board?</p>
        <p *ngIf="isPatternBoard">Company boards cannot be changed. Would you like to save this to a new board?</p>
    </div>

    <mat-form-field class="col-12 mb-2" [floatLabel]="'always'">
        <mat-label>Give your board a name</mat-label>
        <input matInput [(ngModel)]="boardName" placeholder="Board example name" value="" required cdkFocusInitial />
    </mat-form-field>

    <mat-checkbox class="col-12" [(ngModel)]="timeStatic">Save time filters</mat-checkbox>
</div>

<div class="row mt-4 mb-4" *ngIf="boardId !== 0 && !isPatternBoard">
    <div class="col-12">
        <p *ngIf="!saveFirst">
            Are you sure you want to overwrite <strong>{{ boardName }}</strong
            >?
        </p>

        <p *ngIf="saveFirst">
            Do you want to save filter changes for <strong>{{ boardName }}</strong> before loading new board?
        </p>

        <div class="mb-2">
            <mat-checkbox [(ngModel)]="timeStatic">Keep time static</mat-checkbox>
        </div>

        <h5 class="mt-4 mb-0">
            <a href (click)="newBoard()" class="d-flex items-center primary" cdkFocusInitial>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="mr-2">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        fill="#efb758"
                        d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z"
                    />
                </svg>
                Save as new board instead
            </a>
        </h5>
    </div>
</div>

<mat-dialog-actions align="center">
    <button mat-stroked-button color="accent" [mat-dialog-close]="false" class="mr-3" mat-dialog-close>Cancel</button>
    <button mat-stroked-button color="accent" (click)="saveBoard()">Save</button>
</mat-dialog-actions>
