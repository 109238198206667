import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IConfigProductHierarchyResponseModel } from '../interfaces/config-product-hierarchy-response.model';
import { ProductLevel } from '../interfaces/product-level.enum';

@Injectable({
  providedIn: 'root'
})
export class ProductHierarchyService {

    public constructor(private readonly _http: HttpClient) {}

    public async getAttributeValues(productLevel: ProductLevel): Promise<Array<IConfigProductHierarchyResponseModel>> {
        return firstValueFrom(this._http.get<Array<IConfigProductHierarchyResponseModel>>(
            `${environment.apiUrl}/api/config-product-hierarchy/getAll/${productLevel}`));
    }
}
