import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MapsService {

    public constructor(private readonly _http: HttpClient) {}

    public async getAttributeValues(): Promise<Array<string>> {
        return firstValueFrom(this._http.get<Array<string>>(`${environment.apiUrl}/api/planning/GetAttributeValues`));
    }

    public async getTimeValues(): Promise<Array<string>> {
        return firstValueFrom(this._http.get<Array<string>>(`${environment.apiUrl}/api/maps/GetTimeValues`));
    }

    public async getPreviousTimeValue(timeValue: string): Promise<string> {
        return firstValueFrom(
            this._http.get<string>(`${environment.apiUrl}/api/Maps/GetPreviousTimeValue/${timeValue}`)
        );
    }
}
