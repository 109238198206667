import { Component, Input } from '@angular/core';
import { IBudget, IColumn, IColumnGroup } from '../../../../core/models/ly-table.model';
import { OptionsApprovalService } from '../../services/options-approval.service';


@Component({
    selector: 'lynkd-pattern-ly-plan-table',
    templateUrl: './ly-plan-table.component.html',
    styleUrls: ['./ly-plan-table.component.scss']
})
export class LyPlanTableComponent {
    @Input()
    public data: Array<IBudget>;

    public editId: string | null = null;

    @Input()
    public set loading(data: boolean) {
        if (data) {
            this._loading = data;
            return;
        }

        this._loading = false;
    }

    public get loading(): boolean {
        return this._loading;
    }

    @Input()
    public set columns(data: Array<IColumn>) {
        if (data !== null && data !== undefined) {
            this._columns = data.sort((a: IColumn, b: IColumn) => a.order - b.order);
        }
    }

    public get columns(): Array<IColumn> {
        return this._columns;
    }

    @Input()
    public set columnGroups(data: Array<IColumnGroup>) {
        if (data !== null && data !== undefined) {
            this._columnGroups = data.sort((a: IColumnGroup, b: IColumnGroup) => a.order - b.order);
        }
    }

    public get columnGroups(): Array<IColumnGroup> {
        return this._columnGroups;
    }

    @Input()
    public totals: IBudget;

    private _columns: Array<IColumn>;
    private _columnGroups: Array<IColumnGroup>;
    private _loading: boolean;

    public constructor(private readonly _optionsApprovalService: OptionsApprovalService) {
    }

    public getColumnTotal(columnName: string): number {
        return this.totals ? this.totals[columnName] : undefined;
    }

    public getShortId(id: string): string {
        return this._optionsApprovalService.getShortId(id);
    }
}
