<ng-container *ngIf="boards$">
    <mat-nav-list *ngIf="boards$| async as boards; else noItems"
                  class="list-links">
        <a mat-list-item *ngFor="let board of boards"
           (click)="selectBoard($event, board.board_id)" class="board-name">
            <div class="d-flex align-items-center justify-content-between">
                {{ board.board_name }}
            </div>
        </a>
    </mat-nav-list>
</ng-container>

<ng-template #noItems>
    <mat-nav-list class="no-items">
        <a mat-list-item>
            <div class="d-flex align-items-center justify-content-between">
                <span>No Company Boards found</span>
            </div>
        </a>
    </mat-nav-list>
</ng-template>

<ng-template #loading>
    <mat-nav-list class="no-items">
        <a mat-list-item>
            <div class="d-flex align-items-center justify-content-between">
                <span>Updating Company boards</span>
            </div>
        </a>
    </mat-nav-list>
</ng-template>
