import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { IMonthlyOptionColumn, IMonthlyOptionReport, IMonthlyOptionRow } from '../../interfaces/monthly-option-report.interface';
import { IOptionsCalculateRowPayload } from '../../interfaces/options-calculate-row.interface';
import { OptionsApprovalService } from '../../services/options-approval.service';

@Component({
    selector: 'lynkd-pattern-options-monthly-table-bottom',
    templateUrl: './options-monthly-table-bottom.component.html',
    styleUrls: ['./options-monthly-table-bottom.component.scss']
})
export class OptionsMonthlyTableBottomComponent implements OnInit, OnChanges {
    @Input()
    public data: IMonthlyOptionReport;

    @Input()
    public timeValue: string;

    @Input()
    public loading: boolean;

    @Output()
    public readonly edit: EventEmitter<Array<IOptionsCalculateRowPayload>> = new EventEmitter();

    public previousData: IMonthlyOptionReport;

    public editId: { [key: string]: number } | null = null;

    public isInitializing: boolean = false;

    public constructor(private readonly _optionsApprovalService: OptionsApprovalService) {
    }

    public ngOnInit(): void {
        this.initializeProductLevelSkus();
        this.updateTotalRow();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.previousData = changes.data.previousValue;
            this.initializeProductLevelSkus();
            this.updateTotalRow();

            if (this.data) {
                // Trigger an update of the tYOptionsApprovalData in the Plans Component
                // whenever the sku plans are edited
                this.edit.emit(this.getEditedRows());
            }
        }
    }

    public startEdit(columnName: string, rowNumber: number): void {
        this.editId = { [columnName]: rowNumber };
    }

    public stopEdit(): void {
        this.edit.emit(this.getEditedRows());
        this.updateTotalRow();
        this.editId = null;
    }

    public getEditedRows(): Array<IOptionsCalculateRowPayload> {
        const payload: Array<IOptionsCalculateRowPayload> = [];

        // Take slice of rows excluding the total row
        for (const row of this.data.rows.slice(1)) {
            for (const [key, value] of Object.entries(row.sku_plans)) {
                if (!['Total', 'sku_plan'].includes(key))
                    payload.push({
                        attributeValueId: row.attributeValueId,
                        optionId: row.optionId,
                        sku_plan: +value,
                        product_level_value: row.product_level_value,
                        attribute_value: this.data.attribute_value,
                        time_value: this.timeValue,
                        sub_time_value: key
                    });
            }
        }

        return payload;
    }

    public checkSkuPlanTotalMismatch(skuPlans: Record<string, number>, total: number): boolean {
        const skuPlanValues: Array<number> = Object.values(skuPlans).splice(0, Object.values(skuPlans).length - 2);
        const skuPlanSum: number = skuPlanValues.reduce((skuOne: number, skuTwo: number) => skuOne + skuTwo, 0);

        return Number(skuPlanSum) !== Number(total);
    }

    public getSkuPlansTotal(skuPlans: Record<string, number>): number {
        const skuPlanValues: Array<number> = Object.values(skuPlans).splice(0, Object.values(skuPlans).length - 2);
        const skuPlanSum: number = skuPlanValues.reduce((skuOne: number, skuTwo: number) => skuOne + skuTwo, 0);

        return Number(skuPlanSum);
    }

    public getGrandTotal(rows: Array<IMonthlyOptionRow>): number {
        let grandTotal: number = 0;

        if (rows) {
            for (const row of rows) {
                if (row.product_level_value !== "Total") {
                    const skuPlanValues: Array<number> = Object.values(row.sku_plans).splice(0, Object.values(row.sku_plans).length - 2);
                    const skuPlanSum: number = skuPlanValues.reduce((skuOne: number, skuTwo: number) => skuOne + skuTwo, 0);

                    grandTotal += Number(skuPlanSum);
                }
            }
        }

        return grandTotal;
    }

    public allSkusAreZero(row: IMonthlyOptionRow): boolean {
        if (row) {
            for (const [key, value] of Object.entries(row.sku_plans)) {
                if (value !== 0 && key !== 'sku_plan' && key !== 'Total') {
                    return false;
                }
            }
        }

        return true;
    }

    public skusAreSetToPreviouslyGeneratedDefaultValues(newRow: IMonthlyOptionRow): boolean {
        if (this.previousData) {
            const oldRow: IMonthlyOptionRow = this.previousData.rows.find((previousRow: IMonthlyOptionRow) =>
                                                                        previousRow.product_level_value === newRow.product_level_value);

            if (oldRow) {
                const prevGeneratedDefaultRow: IMonthlyOptionRow = this.calculateAndTruncateRowSkus(oldRow);
                // eslint-disable-next-line
                for (const key in newRow.sku_plans) {
                    // if all of the previously generated default sku values are equal to the new
                    // sku values, it means that the user didn't make any changes to the row's
                    // sku values. However, if there's at least one difference, return false because the
                    // user did make at least one change to the skus after the row's skus were auto populated
                    if (
                        key !== "Total" &&
                        key !== "sku_plan" &&
                        prevGeneratedDefaultRow.sku_plans[key] !== newRow.sku_plans[key]
                    ) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    public initializeProductLevelSkus(): void {
        if (this.data) {
            for (const row of this.data.rows) {
                if (
                    row.product_level_value.toLowerCase() !== 'total' &&
                    row.product_level_value.toLowerCase() !== 'sku_plan' &&
                    (this.allSkusAreZero(row) || this.skusAreSetToPreviouslyGeneratedDefaultValues(row))
                ) {
                    const calculatedRow: IMonthlyOptionRow = this.calculateAndTruncateRowSkus(row);

                    if (calculatedRow) {
                        // Edit the row sku_plans directly so that the changes made to the data variable
                        // reflects in the parent component that passed the data variable to this child component
                        Object.keys(calculatedRow.sku_plans).forEach((key: string) => {
                            row.sku_plans[key] = calculatedRow.sku_plans[key];
                        });
                    }
                }
            }
        }
    }

    public calculateAndTruncateRowSkus(row: IMonthlyOptionRow): IMonthlyOptionRow {
        const calculatedRow: IMonthlyOptionRow = structuredClone(row);
        const yearlyOptionSku: number = Number(calculatedRow.sku_plans.sku_plan);

        // Multiply the flow perc column against the yearly sku value and truncate the result
        Object.keys(calculatedRow.sku_plans).forEach((key: string) => {
            if (
                key.toLowerCase() !== 'sku_plan' &&
                key.toLowerCase() !== 'total' &&
                calculatedRow.sku_plans.sku_plan
            ) {
                const column: IMonthlyOptionColumn = this.data.columns.find(
                                (value: IMonthlyOptionColumn) => value.column_name === key);

                if (column) {
                    calculatedRow.sku_plans[key] = Math.trunc(Number(yearlyOptionSku)*(Number(column.flow_perc)/100));
                }else {
                    calculatedRow.sku_plans[key] = 0;
                }
            }
        });

        // Add the missing sku plans to the biggest flow percentage column by
        // ordering the resulting values by flow perc and selecting the highest.
        // Then Subtract the current sku total for all columns from the yearly sku,
        // and add the remaining balance to the highest flow perc column
        const columnsInOrderOfHighestFlowPerc: Array<IMonthlyOptionColumn> = this.data.columns.sort(
                                                (colOne: IMonthlyOptionColumn, colTwo: IMonthlyOptionColumn) =>
                                                                    Number(colTwo.flow_perc) - Number(colOne.flow_perc));
        const highestFlowPercColumnName: string = columnsInOrderOfHighestFlowPerc[0].column_name;
        calculatedRow.sku_plans[highestFlowPercColumnName] =
                                        calculatedRow.sku_plans[highestFlowPercColumnName] +
                                        (Number(yearlyOptionSku) - this.getSkuPlansTotal(calculatedRow.sku_plans));

        return calculatedRow;
    }

    public updateTotalRow(): void {
        if (this.data) {
            const totalRow: IMonthlyOptionRow = this.data.rows.find((row: IMonthlyOptionRow) => row.product_level_value.toLowerCase() === "total");

            if (totalRow) {
                // Sum all of the skus for each column specific column and update the associated
                // sku in the total row sku plans
                Object.keys(totalRow.sku_plans).forEach((key: string) => {
                    totalRow.sku_plans[key] = this.data.rows.reduce((acc: number, row: IMonthlyOptionRow) => {
                        if (row.product_level_value.toLowerCase() !== "total") {
                            return acc + row.sku_plans[key];
                        }else {
                            return acc;
                        }
                    }, 0);
                });
            }
        }
    }

    public getShortId(id: string): string {
        return this._optionsApprovalService.getShortId(id);
    }
}
